<mat-card class="detail-card p-0 rounded">
  <div class="section-header">
    <i class="fas fa-users text-white"></i>
    {{ 'TICKET.PEOPLE' | translate }}
  </div>
  <mat-card-content class="pl-2 pr-2" fxLayout="column" fxLayoutGap="12px">
    <!--Creator-->
    @if (!state.templateMode) {
      <div fxLayout="row">
        <label fxFlex="30" fxFlex.lg="40">{{ 'TICKET.CREATOR' | translate }}</label>
        <div fxFlex="70" fxFlex.lg="60" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
          @if (!ticket.createdFromMail && ticket.creator) {
            <omt-user-link-container [userId]="ticket.creator.id"
                                     [routerLink]="['/profile/general', ticket.creator.id]"></omt-user-link-container>
          } @else {
            <omt-avatar></omt-avatar>
            <span>{{ ticket.createdBy }} ({{ 'TICKET.MAIL_REQUEST' | translate }})</span>
          }
        </div>
      </div>
    }
    <!--Assignee-->
    <div fxLayout="row" fxLayoutAlign="start center" [ngClass]="{'mb-1': state.templateMode}">
      <label fxFlex="30" fxFlex.lg="40">{{ 'TICKET.ASSIGNEE' | translate }}
        @if (state.editMode) {
          <omt-required-symbol></omt-required-symbol>
        }
      </label>
      <div fxFlex="70" fxFlex.lg="60" fxLayout="row" fxLayoutAlign="space-between center">
        <!--No assignee available-->
        @if (!ticket.assignee && !state.editMode) {
          <span>-</span>
        }

        @if (ticket.assignee && !state.editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
            @if (ticket.assignee.user) {
              <omt-user-link-container
                [userId]="ticket.assignee.user.id"
                [routerLink]="['/profile/general', ticket.assignee.user.id]"></omt-user-link-container>
            } @else {
              <omt-avatar size="24" [avatarUrl]="ticket.assignee.avatarUrl"></omt-avatar>
              <span>{{ ticket.assignee.displayName ?? ticket.assignee.name }}</span>
            }
          </div>
        }

        @if (state.editMode) {
          <div fxFlex="100" fxFlex.xl="70" fxLayout="row" fxLayoutAlign="start center">
            <omt-business-unit-dropdown fxFlex="1 0 auto" [(value)]="ticket.assignee" (valueChange)="changeAssignee($event)"
                                        [showDisabled]="false" [clearable]="false"></omt-business-unit-dropdown>
            <button matTooltip="Selbst zuweisen" mat-icon-button (click)="takeResponsibility.emit()">
              <i class="far fa-handshake"></i>
            </button>
          </div>
        }
      </div>
    </div>
    <!--Observer-->
    @if (!state.templateMode) {
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="30" fxFlex.lg="40">{{ 'TICKET.OBSERVER' | translate }}</label>
        <div fxFlex="70" fxFlex.lg="60" fxLayout="row">
          <!--No observer available-->
          @if (!state.editMode) {
            <div fxLayout="row" fxLayoutAlign="start center" [matTooltip]="observerString">
              <i class="far fa-eye cursor-pointer"></i>
              <span>{{ ticket.ticketObservers?.length ?? 0 }}</span>
            </div>
          }
          @if (state.editMode) {
            <omt-ticket-observer-dropdown [(observers)]="ticket.ticketObservers" fxFlex="100" fxFlex.xl="70"
                                          (observersChange)="readObserversIntoString()"
                                          multiple></omt-ticket-observer-dropdown>
          }
        </div>
      </div>
    }

    <!--                External -->
    @if (!state.templateMode) {
      <div fxLayout="row" fxLayoutAlign="start center" class="mb-1">
        <label fxFlex="30" fxFlex.lg="40" [matTooltip]="'TICKET.EXTERNAL_INFOTEXT' | translate">{{ 'TICKET.EXTERNAL' | translate }}</label>
        <div fxLayout="row" fxFlex="70" fxFlex.lg="60">
          @if (!state.editMode) {
            <span>
              {{ ticket.external ? ('GLOBAL.YES' | translate) : ('GLOBAL.NO' | translate) }}
            </span>
          } @else {
            <mat-slide-toggle class="colored" [(ngModel)]="ticket.external"></mat-slide-toggle>
          }
        </div>
      </div>
    }

    <!-- Mail-to-Ticket -->
    @if (ticket.createdFromMail) {
      <div fxLayout="row" fxLayoutAlign="start center" class="mb-1">
        <label fxFlex="30" fxFlex.lg="40" matTooltip="Abweichende(r) Email-Empfänger">Email-Empfänger</label>
        <div fxLayout="row" fxFlex="70" fxFlex.lg="60">
          @if (!state.editMode) {
            <a href="mailto:{{ ticket.requestedBy }}">{{ ticket.requestedBy }}</a>
          } @else {
            <omt-form-input [(value)]="ticket.requestedBy" type="email"></omt-form-input>
          }
        </div>
      </div>
      @if (false) {
        <div fxLayout="row" fxLayoutAlign="start center" class="mb-1">
          <label fxFlex="30" fxFlex.lg="40" matTooltip="Zusätzliche(r) Email-CC-Empfänger">Email-CC-Empfänger</label>
          <div fxLayout="row" fxFlex="70" fxFlex.lg="60">
            @if (!state.editMode) {

            } @else {

            }
          </div>
        </div>
      }
    }

  </mat-card-content>
</mat-card>
